<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/store/components/BrandCard.vue
 * @Author: 张兴业
 * @Date: 2020-12-08 23:09:01
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-10 23:04:30
-->

<template>
  <div class="brand_box">
    <div class="title">合作品牌</div>
    <div class="brand_imgs">
      <div v-for="item in brands" :key="item.id" class="brand_back">
        <img :src="item.icon" alt="" class="brand" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brands: [
        {
          id: 1,
          icon: require("@/assets/brand/brand1.png"),
        },
        {
          id: 2,
          icon: require("@/assets/brand/brand2.png"),
        },
        {
          id: 3,
          icon: require("@/assets/brand/brand3.png"),
        },
        {
          id: 4,
          icon: require("@/assets/brand/brand4.png"),
        },
        {
          id: 5,
          icon: require("@/assets/brand/brand5.png"),
        },
        {
          id: 6,
          icon: require("@/assets/brand/brand6.png"),
        },
        {
          id: 7,
          icon: require("@/assets/brand/brand7.png"),
        },
        {
          id: 8,
          icon: require("@/assets/brand/brand8.png"),
        },
        {
          id: 9,
          icon: require("@/assets/brand/brand9.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.brand_box {
  background: white;
  padding: 60px 30px;
  border-radius: 30px;

  .title {
    font-size: 25px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    white-space: nowrap;
  }

  .brand_imgs {
    margin-top: 25px;
    width: 360px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .brand_back {
      padding: 15px;

      .brand {
        flex: 1;
        width: 90px;
        height: 90px;
      }
    }
  }
}
</style>
